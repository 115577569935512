<template>
  <div>
    <el-form :inline="true" :model="searchForm" class="demo-form-inline">
      <el-form-item label="">
        <el-input
          clearable
          v-model="searchForm.mobile"
          placeholder="请输入手机号"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="query">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      border
      max-height="460"
      :header-cell-style="{ background: '#E5F0FF' }"
      style="width: 100%"
    >
      <el-table-column type="index" label="序号" width="60" />
      <el-table-column prop="mobile" label="手机号" />
      <el-table-column prop="taskStatus" label="状态">
        <template #default="scope">
          <el-tag :type="convurtType(scope.row.taskStatus)">
            <span>{{ convertTaskStatus(scope.row.taskStatus) }}</span>
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="memo" label="执行时间 / 失败原因" />
    </el-table>
    <el-pagination
      background
      @size-change="sizeChangeHandler"
      @current-change="currentChangeHandler"
      :current-page="pagination.pageNo"
      :page-sizes="[10, 15, 20]"
      :page-size="pagination.length"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  name: "messageTaskInfoList",
  data() {
    return {
      taskId: this.$route.query.taskId,
      searchForm: {
        mobile: "",
      },
      querySubmitForm: {
        mobile: "",
      },
      tableData: [],
      pagination: {
        total: 0, //总条数
        pageNo: 1, // 页数
        length: 15, // 每页的条数
      }, // 
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    // 获取列表数据
    getTableData() {
      this.$http
        .request("getTaskDetails", {
          // 参数
          ...this.querySubmitForm,
          taskId: this.taskId,
          page: this.pagination.pageNo,
          limit: this.pagination.length,
        })
        .then((res) => {
          let datas = res.result.data
          datas.forEach(item => {
            if(item.applyDate){
              item.memo = item.applyDate
            }else{
              item.memo = item.updateUser
            }
          });
          this.tableData = datas;
          this.pagination.total = res.result.count;
        })
        .catch(() => {});
    },
    // 查询
    query() {
      this.querySubmitForm = Object.assign({}, this.searchForm);
      this.getTableData((this.pagination.pageNo = 1));
    },
    // 换每页条数
    sizeChangeHandler(length) {
      this.pagination.length = length;
      this.getTableData();
    },
    // 换页
    currentChangeHandler(pageNo) {
      this.pagination.pageNo = pageNo;
      this.getTableData();
    },
    convurtType(val) {
      if (val == 1) {
        return "success";
      } else if (val == 2) {
        return "danger";
      } else{
        return "warning"
      }
    },
    convertTaskStatus(val) {
      if (val == 1) {
        return "成功";
      } else if (val == 2) {
        return "失败";
      }else{
        return "正在执行"
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-pagination {
  margin-top: 30px;
  text-align: right;
}
.ex_box {
  div {
    margin-bottom: 15px;
    line-height: 25px;
  }
}
</style>